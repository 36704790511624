<template>
    <div>
        <div v-if="!isPasswordRecovered" class="row justify-content-md-center">
            <div class="col-md-8 col-xs-12">
                <div class="col-12 login-title-div">
                    <div class="login-title">Восстановление пароля</div>
                </div>
                <div class="form">
                    <ValidationObserver slim v-slot="{ invalid, handleSubmit }">
                        <form @submit.prevent="handleSubmit(restore)">
                            <div class="form-group">
                                <ValidationProvider name="email" rules="required|email">
                                    <input v-model="email" name="email" type="email" class="login-form-inputs"
                                           id="login-input"
                                           aria-describedby="emailHelp"
                                           placeholder="Ваш e-mail" required>
                                </ValidationProvider>
                            </div>
                            <button :disabled="invalid" type="submit" class="btn login-btn mt-5 fl-sm">
                                Восстановить
                            </button>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div v-else class="row justify-content-md-center">
            <div class="col-md-8 col-xs-12">
                <div class="col-12 login-title-div">
                    <div class="password-recovered-title pl-15-xs">Ваш пароль отправлен на e-mail</div>
                </div>
                <div class="form">
                    <form>
                        <router-link :to="{name: 'Login'}" type="button" class="btn login-btn mt-3 fl-sm">Войти в
                            кабинет
                        </router-link>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import api from '@/api'
import {showMessage} from "@/lib/toasted";
import {setLoading, removeLoading} from "@/lib/loading";

export default {
    name: "RecoverPassword",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            email: '',
            isPasswordRecovered: false
        }
    },
    methods: {
        async restore() {
            try {
                setLoading();
                const {data: {status, message}} = await api.account.recoverPassword({
                    email: this.email
                });
                if (status === 'OK') {
                    this.isPasswordRecovered = true;
                } else {
                    showMessage(status, message);
                }
            } catch (e) {
                const data = e.response.data;

                if (e.response.status == 403 && data.status == 'error') {
                    showMessage(data.status, data.message)
                }
            }
            removeLoading();
        }
    },
    metaInfo() {
        return {
            title: `Восстановление пароля - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>
.login-title {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
}

.login-form-inputs {
    width: 302px;
    height: 54px;
    background-color: #ffffff;
    border: solid 1px #ededed;
}

.login-btn {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width: 141px;
    height: 36px;
    border-radius: 2px;
    background-color: #e5173f;
    cursor: pointer;
}

header form {
    max-width: 35rem;
    margin-top: 32px;
    margin-right: auto;
    margin-left: auto;
}

.form-group {
    margin-bottom: 0;
}

.form input {
    width: 100%;
    height: 54px;
    background-color: #ffffff;
    border: solid 1px #ededed;
    padding-left: 19px;
}

.form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    width: 100%;
    height: 16px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #71706f;
}


.login-title-div {
    margin-bottom: 30px;
}

.password-recovered-title {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    position: relative;
    left: -15px;
    color: #ffffff;
    margin: 0;
}

@media (max-width: 991px) {
    header form {
        display: flex;
        flex-direction: column;
        /*align-items: center;*/
    }

    .login-btn {
        align-self: center;
    }

    .password-recovered-title {
        text-align: center;
    }
}

/*Хак для IE*/
.form input:-ms-input-placeholder {
    width: 100%;
    height: 54px;
    background-color: #ffffff;
    border: solid 1px #ededed;
    padding-left: 19px;
}

</style>
